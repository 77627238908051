import { useRef, useState, useEffect } from "react";
import styles from "./Home.module.scss";
import Layout from "../../components/Layout/Layout";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import img1 from "../../assets/images/sf-orange-2.jpeg";
import img2 from "../../assets/images/sf-orange-3.jpeg";
import img3 from "../../assets/images/sf-orange-1.jpeg";
import img4 from "../../assets/images/sf-orange-4.jpeg";
import img5 from "../../assets/images/sf-orange-5.jpeg";
import useFadeIn from "../../animations/useFadeIn";
import MoonLoader from "react-spinners/MoonLoader";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [minLoadTimePassed, setMinLoadTimePassed] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const images = [img2, img3, img4, img5];
  const minLoadingTime = 500;

  const headerRef = useRef(null);
  const splashContainerRef = useRef(null);
  const imageContainersRef = useRef([]);

  const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = url;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = (error) => {
        console.error("Error loading image:", url, error);
        reject(error);
      };
    });
  };

  useEffect(() => {
    const loadImages = async () => {
      setIsLoading(true);
      setMinLoadTimePassed(false);
      setShouldAnimate(false);

      try {
        await Promise.all([
          preloadImage(img1),
          ...images.map((img) => preloadImage(img)),
        ]);
      } catch (error) {
        console.error("Error preloading images:", error);
      } finally {
        setTimeout(() => {
          setMinLoadTimePassed(true);
        }, minLoadingTime);
      }
    };

    loadImages();
  }, []);

  useEffect(() => {
    if (minLoadTimePassed) {
      setIsLoading(false);
      setShouldAnimate(true);
    }
  }, [minLoadTimePassed]);

  useGSAP(() => {
    if (shouldAnimate) {
      const splash = splashContainerRef.current;
      const containers = imageContainersRef.current.filter(Boolean);

      const tl = gsap.timeline();

      const itemsToAnimate = [splash, ...containers];

      tl.set(itemsToAnimate, {
        opacity: 0,
      }).to(
        itemsToAnimate,
        {
          opacity: 1,
          duration: 0.5,
          stagger: 0.15,
          ease: "linear",
        },
        0.25
      );
    }
  }, [shouldAnimate]);

  return (
    <>
      <Layout>
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <MoonLoader
              color={"black"}
              loading={true}
              size={75}
              speedMultiplier={0.5}
            />
          </div>
        ) : (
          <div ref={headerRef} className={styles.homeContainer}>
            <div ref={splashContainerRef} className={styles.splashContainer}>
              <TitleContainer
                title="crimson sky"
                view="view"
                description="san francisco, 2020"
              />
              <div className={styles.homeBanner}>
                <img src={img1} alt=""></img>
              </div>
            </div>
            <div className={styles.imagesContainer}>
              {images.map((image, idx) => {
                let viewIdx = "0" + (idx + 1);
                const isLast = idx === images.length - 1;
                return (
                  <div
                    key={idx}
                    className={`${styles.imageContainer} ${
                      isLast ? styles.noBorderRight : ""
                    }`}
                    ref={(el) => (imageContainersRef.current[idx] = el)}
                  >
                    <TitleContainer
                      title="crimson sky"
                      view={"view " + viewIdx}
                      description=""
                    />
                    <div className={styles.imageWrapper}>
                      <img src={image} alt=""></img>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default Home;
